import { graphql } from 'gatsby'
import { GatsbyFluidImageProps } from 'gatsby-source-sanity'
import React from 'react'
import styled from 'styled-components'
import { CareerForm } from '../components/Forms/CareerForm'
import { glassmorph } from '../components/GlobalStyles/glassmorph'
import { device } from '../components/GlobalStyles/helpers/device'
import { colors } from '../components/GlobalStyles/vars/colors'
import { Layout } from '../components/Layout/Layout'
import { PhoneEmail } from '../components/molecules/contactInfo/PhoneEmail'
import { SplitImage } from '../components/SplitImage'
import { SEO } from '../helpers/SEO'

const PageWrapper = styled.div`
  .rel-pos {
    position: relative;
  }
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  min-height: 725px;
  text-align: center;

  .career {
    display: block;
  }
`

const Glass = styled.div`
  @media ${device.laptop} {
    ${glassmorph}
    position: absolute;
    top: calc(50vh - 80px);
    right: 10%;

    label {
      color: ${colors.brightWhite};
    }
  }
`

interface Props {
  data: {
    file: {
      childImageSharp: {
        fluid: GatsbyFluidImageProps
      }
    }
    sanitySiteSettings: {
      contactInfo: {
        phoneNumber: string
        email: string
        address: {
          googlePlaceId: string
          state: string
          street: string
          street2: string
          city: string
          zip: string
        }
        socialMedia: [
          {
            platform: string
            url: string
            user: string
            _key: string
          },
        ]
      }
    }
  }
}

const Careers = ({ data }: Props): JSX.Element => {
  return (
    <Layout>
      <SEO title="Careers" description="Come work with our team at Queen City Flying Service." />
      <PageWrapper>
        <SplitImage
          className="rel-pos"
          fluid={data.file.childImageSharp.fluid}
          alt="airport terminal with a sign reading 'welcome to cincinnati lunken airport"
        >
          <Body>
            <h1>Work With Us</h1>
            <p>
              Waypoint Aviation is the host facility for Queen City Flying Service. Waypoint is the newest terminal on
              the airport, boasting a beautiful lobby, private office space and conference room, on-site maintenance,
              and a meticulously clean hangar. We are proud to be able to offer a flight training environment unlike the
              typical school experience of dingy classrooms, cluttered hangars, and rundown equipment.
            </p>

            <p>
              Our instructors are flight training professionals with real-world experience, not time-builders looking to
              bail out at the first opportunity. Every client is an individual with specific needs and desires, not a
              number to be pushed through as quickly as possible. In lieu of mandating a specific study program, our
              instructors work with each client to select a training package which best meets the client’s need and
              learning style.
            </p>

            <PhoneEmail
              phoneNumber={data.sanitySiteSettings.contactInfo.phoneNumber}
              email={data.sanitySiteSettings.contactInfo.email}
            />
          </Body>
        </SplitImage>
        <Glass>
          <CareerForm />
        </Glass>
      </PageWrapper>
    </Layout>
  )
}

export default Careers

export const query = graphql`
  {
    file(relativePath: { eq: "HY_1107.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sanitySiteSettings {
      contactInfo {
        phoneNumber
        email
        address {
          googlePlaceId
          state
          street
          street2
          city
          zip
        }
        socialMedia {
          platform
          user
          _key
          url
        }
      }
    }
  }
`
